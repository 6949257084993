import AutocompleteController from './autocomplete_controller'
import { template } from 'lodash-es'

// Select city(ies) or sector(s) with autocompletion.
//
// It supports 3 operation modes:
//   - multiselect: with `tags` and `tagTemplate` targets
//                  (and eventually a `resetField` that will be enabled if there are tags / disabled is there is no tag)
//   - single select / monomorphic: with `sectorId` and/or `cityId` targets
//   - single select / polymorphic: with `placeType` and `placeId` targets
//
export default class extends AutocompleteController {
  static targets = [
    'cityId',
    'sectorId',
    'placeId',
    'placeType',
    'tags',
    'tagTemplate',
    'resetField'
  ]

  connect () {
    super.connect()

    if (this.hasTagsTarget) this.tagTemplate = template(this.tagTemplateTarget.innerHTML)
  }

  selectCity (event) {
    event.preventDefault()
    event.stopPropagation()

    const label = event.currentTarget.dataset.label || event.currentTarget.textContent
    const value = event.currentTarget.dataset.value

    if (this.hasTagsTarget) {
      this.addTag(label, 'by_city_id', value)
      this.inputTarget.value = ""
    } else if (this.hasPlaceTypeTarget) {
      this.placeTypeTarget.value = 'Location::City'
      this.placeIdTarget.value = value
      this.inputTarget.value = label
    } else {
      this.sectorIdTarget.value = null
      this.cityIdTarget.value = value
      this.inputTarget.value = label
    }

    this.inputTarget.focus()
    this.closeResults({ reset: true })
  }

  selectSector (event) {
    event.preventDefault()
    event.stopPropagation()

    const label = event.currentTarget.dataset.label || event.currentTarget.textContent
    const value = event.currentTarget.dataset.value

    if (this.hasTagsTarget) {
      this.addTag(label, 'by_sector_id', value)
      this.inputTarget.value = ""
    } else if (this.hasPlaceTypeTarget) {
      this.placeTypeTarget.value = 'Sector'
      this.placeIdTarget.value = value
      this.inputTarget.value = label
    } else {
      this.cityIdTarget.value = null
      this.sectorIdTarget.value = value
      this.inputTarget.value = label
    }

    this.inputTarget.focus()
    this.closeResults({ reset: true })
  }

  addTag (label, attribute, value) {
    const content = this.tagTemplate({ label, attribute, value })

    this.tagsTarget.insertAdjacentHTML('beforeend', content)
    this.#disableResetField()
  }

  removeTag (event) {
    event.stopImmediatePropagation()

    event.currentTarget.remove()
    if (this.tagsTarget.children.length === 0) this.#enableResetField()
  }

  clear (event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    if (this.hasTagsTarget) {
      this.tagsTarget.innerHTML = null
      this.inputTarget.value = null
    } else if (this.hasPlaceTypeTarget) {
      this.placeTypeTarget.value = null
      this.placeIdTarget.value = null
      this.inputTarget.value = null
    } else {
      this.cityIdTarget.value = null
      this.sectorIdTarget.value = null
      this.inputTarget.value = null
    }

    this.inputTarget.focus()
    this.closeResults({ reset: true })
  }

  // override parent class method to include already selected cities and sectors as query params
  get src () {
    const url = new URL(super.src, window.location.href)
    this.selectedSectorIds.forEach(sectorId => url.searchParams.append("already_selected_sector_ids[]", sectorId))
    this.selectedCityIds.forEach(cityId => url.searchParams.append("already_selected_city_ids[]", cityId))
    return url.toString()
  }

  get selectedSectorIds () {
    if (!this.hasTagsTarget) return []

    const sectorTags = this.tagsTarget.querySelectorAll('input[name="by_sector_id[]"]')
    return Array.from(sectorTags).map(input => input.value)
  }

  get selectedCityIds () {
    if (!this.hasTagsTarget) return []

    const cityTags = this.tagsTarget.querySelectorAll('input[name="by_city_id[]"]')
    return Array.from(cityTags).map(input => input.value)
  }

  #enableResetField () {
    if (this.hasResetFieldTarget) this.resetFieldTarget.disabled = false
  }

  #disableResetField () {
    if (this.hasResetFieldTarget) this.resetFieldTarget.disabled = true
  }
}
