import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'target',
    'loaderTemplate'
  ]

  connect () {
    this.loaders = []
  }

  replaceOuter (event) {
    this.target.outerHTML = this.#getXhrFromEvent(event).response
    this.#removeLoaders()
  }

  replaceInner (event) {
    this.target.innerHTML = this.#getXhrFromEvent(event).response
    this.#removeLoaders()
  }

  append (event) {
    this.target.insertAdjacentHTML('afterend', this.#getXhrFromEvent(event).response)
    this.#removeLoaders()
  }

  prepend (event) {
    this.target.insertAdjacentHTML('beforebegin', this.#getXhrFromEvent(event).response)
    this.#removeLoaders()
  }

  redirectToLocation (event) {
    const xhr = this.#getXhrFromEvent(event)
    const location = xhr.getResponseHeader('location') || xhr.responseURL
    if (location) window.location = location
  }

  displayLoader () {
    if (this.hasLoaderTemplateTarget) {
      this.targetTargets.forEach(target => {
        // Hide target that will be replaced
        target.style.display = "none"

        // Display loader
        this.target.parentElement.insertAdjacentHTML('beforeend', this.loaderTemplateTarget.innerHTML)
        this.loaders.push(this.target.parentElement.lastChild)
      })
    }
  }

  #getXhrFromEvent (event) {
    if (event.type === 'ajax:complete') {
      console.warn("Remote controller: stoping the propagation of ajax:complete event could stop flash messages from being displayed !")
      return event.detail[0]
    } else {
      return event.detail[2]
    }
  }

  #removeLoaders () {
    this.loaders.forEach(loader => loader.remove())
  }

  get target () {
    return this.hasTargetTarget ? this.targetTarget : this.element
  }
}
