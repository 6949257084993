// Custom action option that pushes the event's responseUrl into the browser's history
// This action comes in handy with search engines that work in AJAX to update the browser's url after each search
// example: data-action="ajax:success->remote#replaceOuter:pushState">
// https://stimulus.hotwired.dev/reference/actions#options

const urlWithoutEmptyParams = urlString => {
  const url = new URL(urlString)
  const paramsToRemove = ["button", "commit"]

  url.searchParams.forEach((paramValue, paramName) => {
    if (!paramValue) paramsToRemove.push(paramName)
  })
  paramsToRemove.forEach(paramName => url.searchParams.delete(paramName))

  return url.toString()
}

export function pushState () {
  const callback = ({ event }) => {
    const xhr = event.type === 'ajax:complete' ? event.detail[0] : event.detail[2]

    if (xhr?.responseURL) history.pushState(null, null, urlWithoutEmptyParams(xhr?.responseURL))

    return true
  }
  return ['pushState', callback]
}
